import React from 'react'
import { Helmet } from 'react-helmet'

const baseUrl = 'https://railcam2d.com'
const defaultImagePath = '/twittercard.png'
const twitterUsername = 'j_a_madelaine'
const defaultLanguage = 'en'

export const Seo = ({ title, description, imagePath, pathname }) => {
  const image = `${baseUrl}${imagePath || defaultImagePath}`
  const url = `${baseUrl}${pathname || ''}`

  const isHomePage = url === baseUrl

  return (
    <Helmet
      htmlAttributes={{
        lang: defaultLanguage,
      }}
      title={title}
      titleTemplate={isHomePage ? '%s' : '%s | Railcam 2D'}
    >
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta property="twitter:image:alt" content={title} />
    </Helmet>
  )
}

export default Seo
