import React, { useState, Children } from 'react'
import { useTheme } from 'emotion-theming'
import { Flex, Button } from 'baselift'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'
import { CollapseYIcon, ExpandYIcon } from '../shared/icons'
import { isPath } from '../../helpers/path-helpers'

export const GroupHeader = ({ text }) => {
  const theme = useTheme()
  return (
    <Flex
      css={{
        padding: theme.space(1, 2),
        fontSize: theme.fontSize[4],
      }}
    >
      {text}
    </Flex>
  )
}

export const Item = ({ to, heading, ...other }) => {
  const theme = useTheme()

  const {
    location: { pathname },
  } = globalHistory

  const isCurrentPath = isPath(to, pathname)

  return (
    <Flex
      css={{
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        fontWeight: isCurrentPath ? 'bold' : 'inherit',
        transition: 'background 0.1s ease-in-out',
        '&:hover': {
          background: theme.color.neutral.lighter,
        },
        '&:active': {
          background: theme.color.neutral.light,
        },
      }}
      {...other}
    >
      <Link
        to={to}
        css={{
          display: 'inline-block',
          flex: '1 0 auto',
          padding: theme.space(1, 2),
        }}
      >
        {heading}
      </Link>
    </Flex>
  )
}

export const DocsDropdownChildItem = ({ label, pathWithAnchor, isActive }) => {
  const theme = useTheme()

  return (
    <Link
      to={pathWithAnchor}
      css={{
        fontWeight: isActive ? 'bold' : 'inherit',
        display: 'block',
        position: 'relative',
        padding: theme.space(0.75, 2, 0.75, 6),
        fontWeight: isActive ? 'bold' : 'inherit',
        '&:hover': {
          background: theme.color.neutral.lighter,
        },
        '&:active': {
          background: theme.color.neutral.light,
        },
        transition: 'background 0.1s ease-in-out',
        '&::after': {
          display: 'inline-block',
          position: 'absolute',
          left: theme.space(4),
          top: `calc(50% - ${theme.space(0.5)})`,
          height: theme.space(1),
          borderRadius: '100%',
          content: '""',
          width: theme.space(1),
          background: isActive ? theme.color.primary.light : theme.color.neutral.lighter,
          transition: 'background 0.2s ease-in-out',
        },
      }}
    >
      {label}
    </Link>
  )
}

export const DocsDropdownItem = ({ dropdownItem, expanded, children }) => {
  const theme = useTheme()
  const { title, path, headers: subheadings } = dropdownItem

  const [isExpanded, setIsExpanded] = useState(expanded)

  const {
    location: { pathname },
  } = globalHistory

  const isCurrentPath = isPath(path, pathname)

  return (
    <Flex css={{ flexDirection: 'column', whiteSpace: 'nowrap' }}>
      <Flex
        css={{
          transition: 'background 0.1s ease-in-out',
          '&:hover': {
            background: theme.color.neutral.lighter,
          },
          '&:active': {
            background: theme.color.neutral.light,
          },
        }}
      >
        <Link
          to={path}
          onClick={() => setIsExpanded(true)}
          css={{
            display: 'inline-block',
            flex: '1 0 auto',
            padding: theme.space(0.75, 2, 0.75, 4),
            fontWeight: isCurrentPath ? 'bold' : 'inherit',
            '&::before': {
              display: 'inline-block',
              position: 'absolute',
              bottom: theme.space(1),
              left: theme.space(2),
              top: theme.space(1),
              content: '""',
              width: theme.space(0.5),
              background: isCurrentPath ? theme.color.primary.light : theme.color.neutral.lighter,
            },
          }}
        >
          {title}
        </Link>
        {!!Children.toArray(children).length && (
          <Button
            role="button"
            aria-label={`Expand ${title} menu`}
            onClick={() => setIsExpanded(prev => !prev)}
            css={{
              color: 'inherit',
              flex: `0 0 ${theme.space(4)}`,
              transition: 'background 0.1s ease-in-out',
              '&:hover': {
                background: theme.color.neutral.light,
              },
              '&:active': {
                background: theme.color.neutral.mid,
              },
            }}
          >
            {isExpanded ? (
              <CollapseYIcon
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                aria-hidden="true"
              />
            ) : (
              <ExpandYIcon
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                aria-hidden="true"
              />
            )}
          </Button>
        )}
      </Flex>
      <Flex
        css={{
          flexDirection: 'column',
          height: isExpanded
            ? `calc(${subheadings.length} * ((${theme.lineHeight} * 0.875rem) + ${theme.space(1.5)}))`
            : 0,
          transition: 'height 0.125s ease-in-out',
          overflow: 'hidden',
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}
