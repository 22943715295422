import React from 'react'
import { useTheme } from 'emotion-theming'
import { Box } from 'baselift'

export const Divider = ({ ...other }) => {
  const theme = useTheme()
  return (
    <Box
      css={{
        margin: theme.space(1, 2),
        borderBottom: `1px solid ${theme.color.neutral.light}`,
      }}
      {...other}
    />
  )
}
