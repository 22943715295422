import React, { useEffect, useState, useContext } from 'react'
import { useTheme } from 'emotion-theming'
import { Button, Flex, Text } from 'baselift'
import { Link } from 'gatsby'
import { useScrollPosition } from '../hooks/use-scroll-position'
import { useBreakpoint } from '../hooks/use-breakpoint'
import { globalHistory } from '@reach/router'
import { MenuIcon, UnityIcon, Railcam2DIcon } from './shared/icons'
import { PageContext } from '../context/page-context'
import { isPath, isPathChild } from '../helpers/path-helpers'

export const Header = ({ location, showSidebar, ...other }) => {
  const theme = useTheme()
  const breakpointify = useBreakpoint(theme.breakpoints)
  const top1 = theme.unit(-1)
  const top2 = '0'
  const height = theme.unit(1)
  const { scrollOffset, scrollDelta } = useScrollPosition()
  const [top, setTop] = useState(top2)
  const [transitionTop, setTransitionTop] = useState('top 0.375s ease-in-out')

  const {
    location: { pathname },
  } = globalHistory

  const { pages } = useContext(PageContext)

  useEffect(() => {
    if (scrollOffset < 32 || scrollDelta < 0 - 32) {
      setTop(top2)
      setTransitionTop('top 0.125s ease-in-out')
    } else if (scrollDelta > 0) {
      setTop(top1)
      setTransitionTop('top 0.375s ease-in-out')
    }
  }, [setTop, scrollDelta, scrollOffset, setTransitionTop, theme, top1, top2])

  return (
    <header
      css={{
        background: theme.color.neutral.darkest,
        color: theme.color.neutral.lightest,
        position: 'fixed',
        height,
        top,
        left: 0,
        right: 0,
        transition: transitionTop,
        boxShadow: theme.shadow[1],
        zIndex: 2,
      }}
      {...other}
    >
      <Flex
        css={{
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
          maxWidth: theme.unit(24),
          margin: 'auto',
        }}
      >
        <Flex css={{ marginRight: 'auto', height: theme.unit(1), alignItems: 'center' }}>
          <Button
            role="button"
            aria-label="Open navigation"
            onClick={showSidebar}
            css={breakpointify({
              display: ['flex', 'flex', 'flex', 'none'],
              color: 'inherit',
              flex: '0 0 auto',
              width: theme.unit(1),
              height: theme.unit(1),
              padding: theme.space(2),
              justifyContent: 'center',
              '&:active': {
                background: theme.color.neutral.darker,
              },
            })}
          >
            <MenuIcon
              aria-hidden="true"
              css={{
                width: '100%',
                height: '100%',
              }}
            />
          </Button>
          <Link
            to="/"
            css={breakpointify({
              display: 'inline-block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              paddingLeft: [0, 0, 0, theme.space(2)],
              paddingRight: theme.space(1),
              height: theme.unit(1),
            })}
          >
            <Flex
              css={{
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Railcam2DIcon
                aria-hidden="true"
                css={breakpointify({
                  width: theme.unit(1),
                  height: theme.unit(1),
                  padding: theme.space(1.5),
                })}
                colors={[theme.color.neutral.lightest]}
              />
              <Flex
                css={{
                  marginLeft: theme.space(1),
                  fontSize: theme.fontSize[5],
                }}
              >
                Railcam 2D
              </Flex>
            </Flex>
          </Link>
        </Flex>
        <Flex
          css={{
            height: '100%',
            marginLeft: 'auto',
          }}
        >
          <nav
            css={breakpointify({
              display: ['none', 'none', 'block'],
              height: '100%',
            })}
          >
            {pages &&
              pages.map(p => (
                <Link
                  aria-label={p.title}
                  key={p.id}
                  css={{
                    display: 'inline-block',
                    position: 'relative',
                    height: '100%',
                    lineHeight: theme.unit(1),
                    whiteSpace: 'nowrap',
                    padding: theme.space(0, 2),
                    fontSize: theme.fontSize[4],
                    color:
                      (p.slug === '/' && isPath(p.slug, pathname)) || isPathChild(p.slug, pathname)
                        ? theme.color.primary.light
                        : 'inherit',
                    '&:hover': {
                      color: theme.color.primary.light,
                    },
                    '&:active': {
                      background: theme.color.neutral.darkest,
                    },
                    '&::after': {
                      display:
                        (p.slug === '/' && isPath(p.slug, pathname)) || isPathChild(p.slug, pathname)
                          ? 'inline-block'
                          : 'none',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      content: '""',
                      height: '4px',
                      background: theme.color.primary.light,
                    },
                    transition: 'color 0.15s ease-in-out',
                  }}
                  to={p.slug}
                >
                  {p.title}
                </Link>
              ))}
          </nav>
          <Flex>
            <a
              aria-label="Go to Railcam 2D on the Unity Asset Store"
              href="https://assetstore.unity.com/packages/tools/camera/railcam-2d-103883"
              target="_blank"
              css={breakpointify({
                display: 'flex',
                flex: '0 0 auto',
                height: '100%',
                padding: theme.space(0, 2),
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.color.highlight.mid,
                '&::after': {
                  display: 'inline-block',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  content: '""',
                  height: '4px',
                  background: 'transparent',
                  transition: 'background 0.15s ease-in-out',
                },
                '&:hover': {
                  '&::after': {
                    background: theme.color.highlight.mid,
                  },
                },
                '&:active': {
                  color: theme.color.highlight.dark,
                  '&::after': {
                    background: theme.color.highlight.dark,
                    transition: 'background 0s',
                  },
                },
              })}
            >
              <Text
                css={{
                  fontSize: theme.fontSize[4],
                  height: '100%',
                  lineHeight: theme.unit(1),
                  marginRight: theme.space(1),
                }}
              >
                Store
              </Text>
              <UnityIcon aria-hidden="true" css={{ width: theme.space(3) }} />
            </a>
          </Flex>
        </Flex>
      </Flex>
    </header>
  )
}
