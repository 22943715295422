import React from 'react'
import { Flex } from 'baselift'
import { useTheme } from 'emotion-theming'
import { useBreakpoint } from '../hooks/use-breakpoint'

export const Content = ({ children, ...other }) => {
  const theme = useTheme()
  const breakpointify = useBreakpoint(theme.breakpoints)

  return (
    <Flex
      css={breakpointify({
        flexDirection: 'column',
        margin: `${theme.unit(1)} auto`,
        marginTop: [theme.space(3), theme.space(3), theme.space(5)],
        maxWidth: [theme.space(49), theme.space(49), theme.space(96), theme.space(96), theme.space(106)],
        width: '100%',
      })}
      {...other}
    >
      {children}
    </Flex>
  )
}
