import { theme } from './theme'

export const pageTemplateStyle = {
  '& p': {
    marginBottom: theme.space(3),
  },
  '& h1': {
    fontSize: [theme.fontSize[6], theme.fontSize[6], theme.fontSize[7]],
    marginBottom: [theme.space(3), theme.space(3), theme.space(4)],
  },
  '& h2': {
    fontSize: [theme.fontSize[5], theme.fontSize[5], theme.fontSize[6]],
    marginTop: [theme.space(1), theme.space(1), theme.space(2)],
    marginBottom: [theme.space(3), theme.space(3), theme.space(4)],
  },
  '& h3': {
    fontSize: theme.fontSize[4],
    marginTop: [theme.space(1), theme.space(1), theme.space(2)],
    marginBottom: [theme.space(2), theme.space(2), theme.space(3)],
  },
  '& a': {
    color: theme.color.primary.darker,
    background: `${theme.color.primary.lighter}40`,
    padding: theme.space(0, 0.5),
    borderRadius: theme.space(0.25),
    borderBottom: `1px solid ${theme.color.primary.dark}40`,
    '&:hover': {
      background: theme.color.primary.lightest,
      borderBottom: `1px solid ${theme.color.primary.dark}`,
    },
  },
  '& a code': {
    backgroundColor: 'transparent',
    padding: 0,
  },
  '& hr': {
    textAlign: 'center',
    marginTop: [theme.space(3), theme.space(3), theme.space(5)],
    marginBottom: [theme.space(2), theme.space(2), theme.space(3)],
    border: 'none',
    borderTop: `1px solid ${theme.color.neutral.light}`,
  },
  '& ul': {
    marginBottom: theme.space(3),
    marginLeft: theme.space(3),
    listStyle: 'disc',
  },
  '& ul li': {
    marginBottom: theme.space(1.5),
    display: 'list-item',
  },
  '& blockquote': {
    background: theme.color.highlight.lightest,
    borderLeft: `8px solid ${theme.color.highlight.mid}`,
    marginTop: [theme.space(1), theme.space(1), theme.space(2)],
    marginBottom: [theme.space(4), theme.space(4), theme.space(5)],
    padding: theme.space(3, 2, 0),
  },
  "& pre[class*='language-']": {
    marginTop: [theme.space(1), theme.space(1), theme.space(2)],
    marginBottom: [theme.space(4), theme.space(4), theme.space(5)],
  },
  '& table': {
    display: 'flex',
    marginTop: [theme.space(1), theme.space(1), theme.space(2)],
    marginBottom: [theme.space(4), theme.space(4), theme.space(5)],
  },
  '& thead': {
    display: 'none',
  },
  '& tbody': {
    borderLeft: `1px solid ${theme.color.neutral.light}`,
    borderTop: `1px solid ${theme.color.neutral.light}`,
  },
  '& td': {
    padding: theme.space(1, 2),
    borderRight: `1px solid ${theme.color.neutral.light}`,
    borderBottom: `1px solid ${theme.color.neutral.light}`,
  },
  '& figure': {
    marginTop: theme.space(1),
    marginBottom: theme.space(4),
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  '& img': {
    maxWidth: '100%',
    borderRadius: theme.space(1),
    boxShadow: theme.shadow[2],
  },
}
