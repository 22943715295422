import React, { useEffect, useState, useRef, useContext } from 'react'
import { useTheme } from 'emotion-theming'
import { Flex } from 'baselift'
import { Link } from 'gatsby'
import { Item, DocsDropdownItem, GroupHeader, DocsDropdownChildItem } from './sidebar-item'
import { Divider } from './divider'
import { useBreakpoint } from '../../hooks/use-breakpoint'
import { globalHistory } from '@reach/router'
import { DocsContext, DOCS_TYPE } from '../../context/docs-context'
import { PageContext } from '../../context/page-context'
import { Railcam2DIcon } from '../shared/icons'
import { isPath, getCleanPath } from '../../helpers/path-helpers'

const getActiveAnchorPath = (menuItems, uncleanPath) => {
  const pathname = getCleanPath(uncleanPath)
  const vh = document.documentElement.clientHeight
  const yOffset = window && window.pageYOffset
  let newActiveAnchorid = ''

  const anchorElements = menuItems
    .map(x => {
      return isPath(x.path, pathname) && x.anchorIds.map(a => document.getElementById(a))
    })
    // use reduce instead of flat(1) as flat not supported in Edge browser
    .reduce((res, v) => res.concat(v), [])
    .filter(a => a)

  if (!anchorElements.length) {
    return `/${pathname}`
  }

  if (yOffset === 0) {
    return `/${pathname}#${anchorElements[0].id}`
  }

  if (document.documentElement.scrollHeight === vh + yOffset) {
    return `/${pathname}#${anchorElements[anchorElements.length - 1].id}`
  }

  anchorElements.forEach((a, i) => {
    const rect = a.getBoundingClientRect()
    if (i === 0 || rect.top / vh <= 0.001) {
      newActiveAnchorid = a.id
    }
  })

  return `/${pathname}#${newActiveAnchorid}`
}

export const Sidebar = ({ location, ...other }) => {
  const theme = useTheme()
  const breakpointify = useBreakpoint(theme.breakpoints)

  const sidebarWidth = theme.unit(5)

  const {
    location: { pathname },
  } = globalHistory

  const { docs, activeAnchorPath, setActiveAnchorPath } = useContext(DocsContext)
  const { pages } = useContext(PageContext)

  useEffect(() => {
    if (docs) {
      const setAnchor = () => {
        setActiveAnchorPath(getActiveAnchorPath(docs, pathname))
      }
      setAnchor()
      const interval = setInterval(setAnchor, 100)
      return () => clearInterval(interval)
    }
  }, [pathname, setActiveAnchorPath, docs])

  return (
    <nav
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        width: sidebarWidth,
        overflow: 'hidden auto',
        background: theme.color.neutral.lightest,
        boxShadow: theme.shadow[1],
        fontSize: '0.875rem',
      }}
      {...other}
    >
      <Flex
        css={breakpointify({
          flexDirection: 'column',
          width: '100%',
          paddingTop: [0, 0, 0, theme.unit(1)],
          paddingBottom: theme.unit(2),
        })}
      >
        <Link
          aria-label="Home"
          to="/"
          css={breakpointify({
            display: ['none', 'none', 'none', 'flex'],
            fontSize: theme.fontSize[5],
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: theme.unit(1),
            alignItems: 'center',
            margin: theme.space(0, 2),
            borderBottom: `1px solid ${theme.color.neutral.light}`,
            whiteSpace: 'nowrap',
            lineHeight: theme.unit(1),
          })}
        >
          <Flex>
            <Railcam2DIcon
              aria-hidden="true"
              css={{
                width: theme.unit(1),
                height: theme.unit(1),
                padding: theme.space(1),
              }}
              colors={[theme.color.neutral.darkest]}
            />
            <Flex
              css={{
                marginLeft: theme.space(1),
              }}
            >
              Railcam 2D
            </Flex>
          </Flex>
        </Link>
        <Flex
          css={{
            flexDirection: 'column',
            width: '100%',
            margin: theme.space(1, 0),
          }}
        >
          <Flex
            css={breakpointify({
              display: ['flex', 'flex', 'none'],
              flexDirection: 'column',
            })}
          >
            {pages && pages.map(p => <Item key={p.id} to={p.slug} heading={p.title} />)}
            <Divider />
          </Flex>
          <GroupHeader text={DOCS_TYPE.USER_GUIDE} />
          {docs &&
            docs
              .filter(p => p.type === DOCS_TYPE.USER_GUIDE)
              .map(p => (
                <DocsDropdownItem key={p.id} dropdownItem={p} expanded={isPath(p.path, pathname)}>
                  {p.headers.map((h, i) => {
                    const pathWithAnchor = `${p.path}#${p.anchorIds[i]}`
                    return (
                      <DocsDropdownChildItem
                        key={pathWithAnchor}
                        label={h}
                        pathWithAnchor={pathWithAnchor}
                        isActive={pathWithAnchor === activeAnchorPath}
                      />
                    )
                  })}
                </DocsDropdownItem>
              ))}
          <Divider />
          <GroupHeader text={DOCS_TYPE.API_REFERENCE} />
          {docs &&
            docs
              .filter(p => p.type === DOCS_TYPE.API_REFERENCE)
              .map(p => (
                <DocsDropdownItem key={p.id} dropdownItem={p} expanded={isPath(p.path, pathname)}>
                  {p.headers.map((h, i) => {
                    const pathWithAnchor = `${p.path}#${p.anchorIds[i]}`
                    return (
                      <DocsDropdownChildItem
                        key={pathWithAnchor}
                        label={h}
                        pathWithAnchor={pathWithAnchor}
                        isActive={pathWithAnchor === activeAnchorPath}
                      />
                    )
                  })}
                </DocsDropdownItem>
              ))}
        </Flex>
      </Flex>
    </nav>
  )
}
