import { useState, useEffect } from 'react'

export const useScrollPosition = () => {
  const windowGlobal = typeof window !== 'undefined' && window

  const [scrollOffset, setScrollOffset] = useState(windowGlobal ? windowGlobal.pageYOffset : 0)
  const [scrollDelta, setScrollDelta] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const newPageYOffset = windowGlobal.pageYOffset
      setScrollOffset(prevOffset => {
        setScrollDelta(newPageYOffset - prevOffset)
        return newPageYOffset
      })
    }, 33)
    return () => clearInterval(interval)
  }, [windowGlobal, scrollOffset, setScrollOffset, setScrollDelta])

  return { scrollOffset, scrollDelta }
}
