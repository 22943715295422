import { useTheme } from 'emotion-theming'
import { Flex, Text } from 'baselift'
import { Link } from 'gatsby'
import { KeyLines } from './key-lines'
import { useContext } from 'react'
import { PageContext } from '../context/page-context'

export const Footer = ({ ...other }) => {
  const theme = useTheme()

  const { pages } = useContext(PageContext)

  return (
    <footer
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: theme.unit(3),
        background: theme.color.neutral.lighter,
        display: 'flex',
        justifyContent: 'center',
      }}
      {...other}
    >
      <KeyLines
        css={{
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Flex
          css={{
            alignItems: 'center',
          }}
        >
          {pages &&
            pages.map(p => (
              <Text
                key={p.id}
                css={{
                  display: 'inline-block',
                  textAlign: 'center',
                  padding: theme.space(0, 1),
                }}
              >
                <Link
                  to={p.slug}
                  css={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {p.title}
                </Link>
              </Text>
            ))}
        </Flex>
        <Flex css={{ alignItems: 'center' }}>
          <Text css={{ overflow: 'hidden', whiteSpace: 'nowrap', fontSize: theme.fontSize[2] }}>
            Jonathan Madelaine &copy; {new Date(Date.now()).getFullYear()}
          </Text>
        </Flex>
      </KeyLines>
    </footer>
  )
}
