import React from 'react'
import { Box } from 'baselift'

export const UiMask = ({ ...other }) => {
  return (
    <Box
      css={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: '#0000007f',
      }}
      {...other}
    />
  )
}
