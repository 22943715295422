import React, { useState } from 'react'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { KeyLines } from '../components/key-lines'
import { Flex } from 'baselift'
import { useTheme } from 'emotion-theming'
import { Sidebar } from '../components/sidebar/sidebar'
import { UiMask } from '../components/ui-mask'
import { useBreakpoint } from '../hooks/use-breakpoint'

export const Layout = ({ children, location }) => {
  const theme = useTheme()
  const breakpointify = useBreakpoint(theme.breakpoints)

  const [isSidebarOverlayVisible, setIsSidebarOverlayVisible] = useState(false)

  return (
    <Flex
      css={{
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <Header location={location} showSidebar={() => setIsSidebarOverlayVisible(true)} css={{ zIndex: 4 }} />
      <main
        css={{
          display: 'flex',
          justifyContent: 'center',
          margin: theme.unit(1, 0, 3),
          zIndex: 1,
        }}
      >
        <KeyLines>{children}</KeyLines>
      </main>
      <Footer css={{ zIndex: 2 }} />
      <UiMask
        onClick={() => {
          setIsSidebarOverlayVisible(false)
        }}
        css={breakpointify({
          visibility: [
            isSidebarOverlayVisible ? 'visible' : 'hidden',
            isSidebarOverlayVisible ? 'visible' : 'hidden',
            isSidebarOverlayVisible ? 'visible' : 'hidden',
            'hidden',
          ],
          zIndex: isSidebarOverlayVisible ? 5 : 0,
          background: isSidebarOverlayVisible ? '#0000007f' : 'transparent',
          transition: 'background 0.25s cubic-bezier(0.5, 0, 0.25, 1)',
        })}
      />
      <Sidebar
        css={breakpointify({
          transform: [
            isSidebarOverlayVisible ? 'none' : 'translateX(-100%)',
            isSidebarOverlayVisible ? 'none' : 'translateX(-100%)',
            isSidebarOverlayVisible ? 'none' : 'translateX(-100%)',
            'none',
          ],
          transition: 'transform 0.25s cubic-bezier(0.5, 0, 0.25, 1)',
          zIndex: [6, 6, 6, 3],
        })}
      />
    </Flex>
  )
}

Layout.defaultProps = {
  location: {
    pathname: '',
  },
}
