import React from 'react'
import { Icon } from 'baselift'

export const CollapseYIcon = props => <Icon pathDef={['M7 14l5-5 5 5z']} {...props} />

export const ExpandYIcon = props => <Icon pathDef={['M7 10l5 5 5-5z']} {...props} />

export const MenuIcon = props => <Icon pathDef={['M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z']} {...props} />

export const UnityIcon = props => (
  <Icon
    pathDef={[
      'm 15.262352,12.0273 4.198986,-7.2514 2.029,7.2514 -2.029053,7.2486 z m -2.046521,1.1776 4.199515,7.2488 -7.313844,-1.8735 -5.283691,-5.3753 z m 4.198623,-9.607 -4.198623,7.2509 H 4.817838 L 10.101536,5.4719 Z M 23.408721,9.5602 20.847051,0.0296 11.285708,2.5834 9.870339,5.0724 6.998356,5.0404 0,12.0171 6.998354,18.9913 h 0.0021 l 2.870747,-0.034 1.417382,2.4893 L 20.84869,24 23.410245,14.471 21.955354,12.003 23.410245,9.5368 Z',
    ]}
    {...props}
  />
)

export const Railcam2DIcon = props => (
  <Icon
    viewBox="0 0 224.36666 186.26649"
    pathDef={[
      'm 182.03324,0 a 42.333312,42.333312 0 0 0 -42.33331,42.333312 42.333312,42.333312 0 0 0 9.06869,26.057312 L 117.5168,135.70276 a 25.399988,25.399988 0 0 0 -3.21686,-0.23616 25.399988,25.399988 0 0 0 -5.81721,0.71003 L 59.220644,70.49437 50.799974,59.266637 61.643234,66.920428 A 16.933324,16.933324 0 0 0 63.499968,59.266637 16.933324,16.933324 0 0 0 46.566643,42.333312 16.933324,16.933324 0 0 0 29.633318,59.266637 16.933324,16.933324 0 0 0 32.555619,68.770963 L 15.660018,97.735071 A 12.699994,12.699994 0 0 0 12.699994,97.366618 12.699994,12.699994 0 0 0 0,110.06661 12.699994,12.699994 0 0 0 12.699994,122.7666 12.699994,12.699994 0 0 0 25.399987,110.06661 12.699994,12.699994 0 0 0 24.168022,104.64059 l -7.234697,5.42602 5.458063,-8.18709 17.921892,-26.883102 a 16.933324,16.933324 0 0 0 6.253363,1.203544 16.933324,16.933324 0 0 0 2.029851,-0.138494 l 53.003456,84.805122 -11.153321,-8.63513 a 25.399988,25.399988 0 0 0 -1.546674,8.63513 25.399988,25.399988 0 0 0 25.399985,25.39998 25.399988,25.399988 0 0 0 25.39999,-25.39998 25.399988,25.399988 0 0 0 -4.08967,-13.77642 L 175.02024,84.034106 190.4999,59.266637 183.10243,84.6289 A 42.333312,42.333312 0 0 0 224.36655,42.333312 42.333312,42.333312 0 0 0 182.03324,0 Z',
    ]}
    {...props}
  />
)
