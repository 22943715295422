import React from 'react'
import { Flex } from 'baselift'
import { useTheme } from 'emotion-theming'

export const KeyLines = ({ children, ...other }) => {
  const theme = useTheme()

  const marginX = theme.space(2)

  return (
    <Flex
      css={{
        height: '100%',
        width: `calc(100% - 2 * ${marginX})`,
        maxWidth: theme.space(235),
        flexDirection: 'column',
        margin: `0 ${marginX}`,
      }}
      {...other}
    >
      {children}
    </Flex>
  )
}
